<template>
  <div class="d-flex w-100">
    <div class="fw-bold w-50">{{ title }}</div>
    <div v-if="content" class="w-50">
      <TruncatedText class="w-100">{{ content }}</TruncatedText>
      <small v-if="smallContent">{{ smallContent }}</small>
    </div>
    <slot v-else></slot>
  </div>
</template>

<script>
import { TruncatedText } from '@/modules/core';

export default {
  components: { TruncatedText },
  props: {
    title: { type: String, required: true },
    content: { type: String, default: null },
    smallContent: { type: String, default: null },
  },
  setup() {},
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

small {
  color: $typography-secondary;
}

p {
  color: $typography-primary;
}
</style>
